@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes draw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate-write {
  display: inline-block;
  overflow: hidden;
  animation: draw 3s steps(30) forwards;
}
